import i18n from '@/i18n'
import { ApplicationModel, InquiryDto } from '@/store/inquiry/types'
import { INQUIRE_TALENT } from '@/store/inquiry/actions'
import { SimpleServerResponse } from '@/store/types'

export const ACCEPT = 'ACCEPT'
export const DECLINE = 'DECLINE'

export const getCompanyInquiryConfirmationHeadingForStudent = (action: string): string => {
  switch (action) {
    case ACCEPT: return i18n.t('inquiries.accept-request').toString()
    case DECLINE: return i18n.t('inquiries.decline-request').toString()
    default: return ''
  }
}

export const getCompanyInquiryConfirmationExplanationForStudent = (action: string): string => {
  switch (action) {
    case ACCEPT: {
      return '<p>' + i18n.t('inquiries.accept-request-confirmation').toString() + '</p>' +
        '<p>' + i18n.t('talent-inquiry.talent-accept-help').toString() + '</p>' +
        '<ul>' +
        '<li>' + i18n.t('main-menu-student.base-data').toString() + '</li>' +
        '<li>' + i18n.t('main-menu-student.application-data').toString() + '</li>' +
        '<li>' + i18n.t('main-menu-student.cv-data').toString() + '</li>' +
        '</ul>'
    }

    case DECLINE: {
      return '<p>' + i18n.t('talent-inquiry.talent-decline-help').toString() + '</p>' +
        '<p>' + i18n.t('inquiries.decline-request-confirmation').toString() + '</p>'
    }

    default: {
      return ''
    }
  }
}

export const getTalentRequestConfirmationExplanationForCompany = (action: string): string => {
  switch (action) {
    case ACCEPT: {
      return '<p>' + i18n.t('inquiries.accept-request-confirmation').toString() + '</p>'
    }

    case DECLINE: {
      return '<p>' + i18n.t('company-inquiry.company-decline-help').toString() + '</p>' +
        '<p>' + i18n.t('inquiries.decline-request-confirmation').toString() + '</p>'
    }

    default: {
      return ''
    }
  }
}

export const getAcceptDeclinePositiveFeedback = (action: string): string => {
  switch (action) {
    case ACCEPT: return i18n.t('inquiries.request-accepted').toString()
    case DECLINE: return i18n.t('inquiries.request-declined').toString()
    default: return ''
  }
}

export async function sendTalentInquiry (self: Vue, companyId: string, jobAdId: string, talentId: string, inquiryText: ApplicationModel): Promise<InquiryDto> {
  const inquiryModel: InquiryDto = {
    companyId: companyId,
    inquiryText: inquiryText !== null ? inquiryText.text : undefined,
    jobAdId: jobAdId,
    talentId: talentId
  }
  return self.$store.dispatch(INQUIRE_TALENT, inquiryModel).then((data: InquiryDto) => {
    self.$bvModal.hide('talent-inquiry-modal')
    self.$root.$emit('alert', self.$i18n.t('job-ad.matching.inquiry-successful').toString(), self.$i18n.t('info.success').toString(), false)
    return Promise.resolve(data)
  }).catch((error: SimpleServerResponse) => {
    return Promise.reject(error.message === 'inquiry already existent' ? self.$t('talent-inquiry.inquiry-already-existent').toString() : self.$t('warning.general-error-message').toString())
  })
}
