import { render, staticRenderFns } from "./InquiryPageLayout.vue?vue&type=template&id=8e4d2cda&scoped=true&"
import script from "./InquiryPageLayout.vue?vue&type=script&lang=ts&"
export * from "./InquiryPageLayout.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8e4d2cda",
  null
  
)

export default component.exports