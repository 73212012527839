


















































































































































































































































































































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import LoadingSpinner from '@/components/common/LoadingSpinner.vue'
import ResultsPage from '@/components/search-results/ResultsPage.vue'
import SearchResult from '@/components/search-results/SearchResult.vue'
import ConfirmationModal from '@/components/common/ConfirmationModal.vue'
import { ACCEPT, DECLINE } from '@/common/inquiry'
import { hasRight } from '@/utils/rights'
import Rights from '@/common/rights'

@Component({
  components: { ResultsPage, LoadingSpinner, SearchResult, ConfirmationModal }
})
export default class InquiryPageLayout extends Vue {
  @Prop({ required: true })
  proposer: boolean

  @Prop({ required: true })
  isLoading: boolean

  @Prop({ required: true })
  showErrorMessage: boolean

  @Prop({ required: true })
  errorMessage: string

  @Prop({ required: true })
  headingFirstSection: string

  @Prop({ required: true })
  headingSecondSection: string

  @Prop({ required: true })
  headingThirdSection: string

  @Prop({ required: true })
  dateProperty: string

  @Prop({ required: true })
  titleProperty: string[]

  @Prop()
  subTitleProperty: string

  @Prop()
  bodyProperty: string

  @Prop()
  linkProperty: string

  @Prop()
  missingTitle: string

  @Prop()
  missingSubTitle: string

  @Prop({ required: true })
  openInquiries: never

  @Prop({ required: true })
  acceptedInquiries: never

  @Prop({ required: true })
  declinedInquiries: never

  @Prop({ required: true })
  sortable: string[]

  @Prop({ required: true })
  resultType: string

  @Prop({ required: true, default: '' })
  modalHeading: string

  @Prop({ required: true, default: '' })
  modalExplanation: string

  desiredAction: string
  selectedItem: never

  get DECLINE (): string {
    return DECLINE
  }

  get ACCEPT (): string {
    return ACCEPT
  }

  get canInquireTalent (): boolean {
    return hasRight(Rights.INQUIRE_TALENT)
  }

  get canInquireCompany (): boolean {
    return hasRight(Rights.INQUIRE_COMPANY)
  }

  get canAnswer (): boolean {
    return hasRight(Rights.INQUIRY_ANSWER)
  }

  openConfirmationModal (desiredAction: string, item: never): void {
    this.desiredAction = desiredAction
    this.selectedItem = item
    this.$emit('open-confirmation-modal', desiredAction, item)
    this.$bvModal.show('confirmation-modal-inquiry')
  }

  downloadCv (item: never): void {
    this.$emit('downloadCv', item)
  }
}
